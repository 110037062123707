<template>
    <div class="home">
        <van-nav-bar class="van-nav-bar--fixed" title="六合图库" left-arrow  @click-left="onClickLeft">
        </van-nav-bar>

        <div class="search-hd">
            
                <van-search
                    v-model="value"
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
                    shape="round"
                    background="#4fc08d"
                
                >
                </van-search>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="iscolour" :options="option1" />
                </van-dropdown-menu>
             
        </div>
 

        <van-index-bar>
            <div v-for="(items,key) in list" :key="key">
                <van-index-anchor :index="key"/>
                <van-cell v-for="item in items" :key="item.id" :title="item.name" @click="goToUrlPicture(item.journal,item.id)"><span class="number">{{item.journal}}</span></van-cell>
            </div>

        
        </van-index-bar>
    </div>
</template>

<script>
export default {
  data() {
    return {
      year_id:1,
      lotto_id:2,
      information_id:0,
      active: 'picture',
      list:[],
      loading: false,
      finished: false,
      iserror:false,
      page:1,
      message:'',
      value:'',
      iscolour: 0,
      option1: [
        { text: '选择色彩', value: 0 },
        { text: '彩色', value: 1 },
        { text: '黑白', value: 2 },
      ],
    };
  },
  mounted() {
    this.active='picture';
        this.getData();
    },
    methods: {
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        onSearch(){
            this.getData();
        },
        goToUrlPicture(journal_id,information_id){
            //this.$router.push({ path: '/picture/show', params: { id: id,information_id:information_id } })  
            this.$router.push('/picture/show?journal_id='+parseInt(journal_id)+'&information_id='+(!isNaN(information_id) ?information_id:0));
            //this.$router.push('/pictureshow?id='+id);
        },
        getData() {
            this.$http
                .post("api/picture/category",{iscolour:this.iscolour,search:this.value})
                .then(function (response) {
                   
                    if (response.data.code == "ok") {
                        this.list = response.data.data.list;
                        this.loading = false;
                    } else {
                        this.iserror = true;
                        this.message = response.data.msg;
                    }
                    this.loading = false;
                   // console.log(this.list);
                });
    
        },

    },
};
</script>


<style>
.van-index-anchor {
    color: #07c160;
    font-size: .32rem;
}
.number {
    position: absolute;
    right: 0.1rem;
    color: #07c160;
    padding: 0 0.3rem;
    background: #f2f2f2;
    border-radius: 0.04rem;
    font-size: .24rem;
}
.background-color{background-color: #ffffff;}
.van-search{
    margin-top: 0px;
}
.van-index-bar__sidebar {
    width: 20px;
}
</style>
